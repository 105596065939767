import {
  Button,
  Empty,
  Form,
  Input,
  Modal,
  Space,
  Spin,
  Typography,
  message,
  notification,
} from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useAuth } from "../../../context/auth-context";
import { LoadingOutlined } from "@ant-design/icons";
import OtpInput from "../OtpInput";

function ChangePasswordModal({
  openchangePasswordModal,
  setOpenChangePasswordModal,
}) {
  const authData = useAuth();
  const { logoutHandler } = authData;
  useEffect(() => {
    if (openchangePasswordModal) {
      sendOTP();
    }
  }, [openchangePasswordModal]);

  const [otpSent, setOtpSent] = useState(false);
  const [otpLoading, setOtpLoading] = useState(true);
  const sendOTP = () => {
    setOtpLoading(true);
    let contact = authData.currUser.contact?.toString() ?? "";
    axios
      .post(`/apiV1/generate-otp/`, {
        contact: contact,
        method: "reset",
      })
      .then((res) => {
        setOtpSent(true);
        message.success(
          `OTP is sent to mobile +91-XXXXXX${contact?.substring(
            contact.length - 4
          )}`
        );
      })
      .catch((err) => {
        message.error(err?.response?.data?.message ?? "Something went wrong!");
        console.log(err);
      })
      .finally(() => {
        setOtpLoading(false);
      });
  };

  const [otp, setOtp] = useState("");
  const [otpVerified, setOtpVerified] = useState(false);
  const [otpVerifyLoading, setOtpVerifyLoading] = useState(false);
  const verifyOTP = () => {
    setOtpVerifyLoading(true);
    let contact = authData.currUser.contact?.toString() ?? "";
    axios
      .post(`/apiV1/verify-otp/`, {
        contact: contact,
        otp: otp,
        method: "reset",
      })
      .then((res) => {
        setOtpVerified(true);
        message.success(`OTP verified!`);
      })
      .catch((err) => {
        message.error(err?.response?.data?.message ?? "Something went wrong!");
        console.log(err);
      })
      .finally(() => {
        setOtpVerifyLoading(false);
      });
  };

  const [loader, setLoader] = useState(false);
  const onResetHandler = (values) => {
    setLoader(true);
    if (values?.password !== values.confirm_password) {
      message.error("Confirm Password is not same as Password");
      return;
    }
    axios
      .put("apiV1/change-password/", values)
      .then((res) => {
        setOpenChangePasswordModal(false);
        logoutHandler();
        notification.info({
          message: "Password changed successfully! Please login again!",
        });
      })
      .catch((err) => {
        console.log(err?.message);
        message.error(err?.response?.data?.message ?? "Something went wrong!");
      })
      .finally(() => {
        setLoader(false);
      });
  };
  const contact = authData.currUser.contact?.toString() ?? "";
  const onCloseModal = () => {
    setOpenChangePasswordModal(false);
    setOtpSent(false);
    setOtpVerified(false);
  };
  return (
    <div>
      <Modal
        title="Change Password "
        open={openchangePasswordModal}
        footer={null}
        onCancel={onCloseModal}
      >
        {otpLoading ? (
          <div className="my-3 d-flex justify-content-center">
            <Space direction="vertical" align="center">
              <Spin indicator={<LoadingOutlined />} size="large" />
              <Typography.Text type="secondary" className="th-14">
                +91-XXXXXX{contact?.substring(contact.length - 4)} will recieve
                an OTP
              </Typography.Text>
            </Space>
          </div>
        ) : otpSent && !otpVerified ? (
          <div className="my-3">
            <div className="text-center ">
              <Typography.Text type="secondary" className="text-center">
                We have sent an OTP to +91-XXXXXX
                {contact?.substring(contact.length - 4)}
              </Typography.Text>
            </div>
            <div className="d-flex flex-column align-items-center justify-content-center my-3">
              <div className="w-100" style={{ maxWidth: "300px" }}>
                <OtpInput
                  length={6}
                  onOtpChange={(value) => {
                    setOtp(value);
                  }}
                />
              </div>
              <Button
                disabled={otp.length != 6}
                type="primary"
                htmlType="submit"
                className="mt-3 w-100"
                style={{ maxWidth: "300px" }}
                onClick={verifyOTP}
                loading={otpVerifyLoading}
              >
                Submit
              </Button>
            </div>
          </div>
        ) : (
          !otpVerified && (
            <Empty description="Something is wrong with the mobile number." />
          )
        )}
        {otpVerified && (
          <Form
            name="ResetForm"
            initialValues={{ remember: true }}
            onFinish={onResetHandler}
            autoComplete="off"
            layout="vertical"
          >
            <Form.Item
              label="New Password"
              name="password"
              rules={[
                { required: true, message: "Please input your Password!" },
              ]}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item
              label="Confirm New Password"
              name="confirm_password"
              rules={[
                { required: true, message: "Please input your Password!" },
              ]}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item>
              <Button
                loading={loader}
                type="primary"
                htmlType="submit"
                className="w-100"
              >
                Submit
              </Button>
            </Form.Item>
          </Form>
        )}
      </Modal>
    </div>
  );
}

export default ChangePasswordModal;
