import React from "react";
import Image404 from "../../assest/images/404_img.png";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";
const NotFound = () => {
  const navigate = useNavigate();
  return (
    <>
      <div
        className=""
        style={{ display: "grid", placeItems: "center", height: "100vh" }}
      >
        <div className="text-center">
          <img src={Image404} height={200} />
          <p className="th-16 m-0 th-fw-500">
            We tried but we couldn't find the page you were looking for. We will
            do better next time.
          </p>
          <p className="th-14 m-0 th-grey">
            We want to help you as much as possible. You are invited to our home
            page
          </p>
          <Button
            className="mt-3"
            onClick={() => navigate("/")}
            type="primary"
            size="large"
          >
            Back to home
          </Button>
        </div>
      </div>
    </>
  );
};

export default NotFound;
