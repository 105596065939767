import React, { useEffect, useState } from "react";
import { Layout, Menu, Drawer, Space, Modal, Button, Progress } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import MyDesktop from "../../../UtilComponents/MyDesktop";
import MyMobile from "../../../UtilComponents/MyMobile";
import "./index.css";
import Logo from "../../../../assest/images/favIcon.png";
import { menu, settingsMenu } from "../DashbordMenu";
import { useAuth } from "../../../../../context/auth-context";
import {
  SettingOutlined,
  DoubleRightOutlined,
  ProfileOutlined,
} from "@ant-design/icons";
const { Sider } = Layout;
const Sidebar = ({ collapsed, setCollapsed, colorBgContainer }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const authData = useAuth();
  const [warningModal, setWarningModal] = useState(false);
  const [nextRoute, setNextRoute] = useState();
  const currUserData = authData?.currUser;
  const [defaultSelectedMenu, setDefaultSelectedMenu] = useState(["/"]);
  let menus = [];
  const menuToShow = location.pathname?.includes("config")
    ? settingsMenu
    : menu;
  let role = currUserData?.roles?.id;
  for (let i = 0; i < menuToShow?.length; i++) {
    let obj = menuToShow[i];
    let newChildren = [];
    if (menuToShow[i]?.children && menuToShow[i]?.children?.length > 0) {
      let children = menuToShow[i]?.children;
      for (let j = 0; j < children?.length; j++) {
        children[j].onClick = () => {
          if (location.pathname === "/prescription") {
            setWarningModal(true);
            setNextRoute(children[j].route);
          } else {
            navigate(children[j].route);
          }
        };
        children[j].key = children[j].key?.toString();
        if (children[j].roles?.includes(role)) {
          newChildren.push(children[j]);
        }
      }
      obj.children = newChildren;
    } else if (menuToShow[i]?.route) {
      menuToShow[i].onClick = () => {
        if (location.pathname === "/prescription") {
          setWarningModal(true);
          setNextRoute(menuToShow[i].route);
        } else {
          navigate(menuToShow[i].route);
        }
      };
    }
    obj.key = menuToShow[i]?.key?.toString();
    if (obj?.roles?.includes(role)) menus.push(obj);
  }
  useEffect(() => {
    let selectedKeys = findSelectedMenu(window.location.pathname);
    setDefaultSelectedMenu(selectedKeys);
  }, [window.location.pathname]);

  const findSelectedMenu = (pathname) => {
    for (let i = 0; i < menuToShow?.length; i++) {
      let obj = menuToShow[i];
      if (obj?.children && obj?.children?.length > 0) {
        let selectedChildren = obj?.children?.filter(
          (e) => e?.route === pathname
        );
        if (selectedChildren?.length > 0) {
          return [obj?.key, selectedChildren[0]?.key];
        }
      } else if (obj?.route == pathname) {
        return [obj?.key];
      }
    }
    return [-1];
  };
  return (
    <React.Fragment>
      <MyDesktop>
        <Sider
          collapsible
          collapsed={collapsed}
          onCollapse={() => {
            setCollapsed(!collapsed);
          }}
          style={{
            background: "#222C3C",
            maxHeight: "98vh",
            overflowY: "scroll",
            overflowX: "hidden",
          }}
          className="th-hidden-scrollbar"
          zeroWidthTriggerStyle={{
            width: "78px",
          }}
        >
          <div className="d-flex flex-column justify-content-between h-100">
            <div className="">
              <Space
                className="demo-logo-vertical p-2 py-3 text-center text-white d-flex justify-content-center"
                align="end"
                style={{ letterSpacing: "1px", fontSize: "16px", gap: 2.2 }}
              >
                <img src={Logo} height={30} />
                {!collapsed && "ellDone"}
              </Space>
              <Menu
                theme="dark"
                className=""
                style={{ background: "#222C3C" }}
                mode="inline"
                selectedKeys={defaultSelectedMenu}
                items={menus}
              />
            </div>
            <div>
              <div></div>
              {/* <div
                className="d-flex bg-white gap-3 py-2 my-2 th-pointer"
                onClick={() => {
                  navigate(`/profile`);
                }}
              >
                <div className="d-block text-center p-auto m-auto">
                  {!collapsed && (
                    <span className="px-2 text-black">
                      Profile Completion Status
                    </span>
                  )}
                  {!collapsed && <DoubleRightOutlined className="th-16" />}
                  {collapsed && <ProfileOutlined className="th-16" />}
                  <Progress percent={20} size="small" className="w-75 px-2" />
                </div>
              </div> */}

              {!window.location.pathname.includes("/config") &&
                [4, 7, 8]?.includes(role) && (
                  <div className="d-flex gap-3 px-4 th-pointer">
                    <div
                      onClick={() => {
                        if (location.pathname === "/prescription") {
                          setWarningModal(true);
                          if (role == 8) setNextRoute(`/config/office-profile`);
                          else {
                            setNextRoute(`/config/doctor-profile`);
                          }
                        } else {
                          if (role == 8) navigate(`/config/office-profile`);
                          else {
                            navigate(`/config/doctor-profile`);
                          }
                        }
                      }}
                      className="p-auto m-auto"
                      style={{ color: "#C7CACE" }}
                    >
                      <SettingOutlined className="th-16" />
                      {!collapsed && <span className="px-2">Settings</span>}
                    </div>
                  </div>
                )}
            </div>
          </div>
        </Sider>
      </MyDesktop>
      <MyMobile>
        <Drawer
          style={{ maxWidth: "256px", background: "#2a3649", padding: 0 }}
          placement="left"
          open={!collapsed}
          className="th-mobile-sidebar"
          onClose={() => {
            setCollapsed(!collapsed);
          }}
          closeIcon={null}
          contentWrapperStyle={{ width: "256px" }}
          title={
            <Space
              className="demo-logo-vertical p-2 text-center text-white d-flex justify-content-center"
              align="end"
              style={{ letterSpacing: "1px", fontSize: "16px", gap: 1 }}
            >
              <img src={Logo} height={30} />
              {"ellDone"}
            </Space>
          }
        >
          <Menu
            theme="dark"
            mode="inline"
            style={{ background: "#2a3649" }}
            items={menus}
            defaultSelectedKeys={[defaultSelectedMenu]}
          />
        </Drawer>
      </MyMobile>
      <Modal
        title="Warning"
        open={warningModal}
        closable={null}
        footer={[
          <Button
            onClick={() => {
              navigate(nextRoute);
              setWarningModal(false);
            }}
          >
            Sure
          </Button>,
          <Button
            onClick={() => {
              setWarningModal(false);
            }}
            type="primary"
          >
            Cancel
          </Button>,
        ]}
      >
        All changes will be lost! Are you sure?
      </Modal>
    </React.Fragment>
  );
};

export default Sidebar;
