import { Modal, Spin } from "antd";
import React from "react";
import Logo from "../assest/images/welldone.png";
function GlobalLoading({ loading }) {
  return (
    <Modal open={loading} title={null} closable={false} centered footer={null}>
      <div className="text-center py-3">
        <div className="">
          <img src={Logo} height={50} />
        </div>
        <div className="pt-3 text-primary th-fw-500">
          <Spin spinning className="px-2" /> Please wait while we make
          everything perfect for you
        </div>
      </div>
    </Modal>
  );
}

export default GlobalLoading;
