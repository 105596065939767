import { createContext, useContext, useReducer } from "react";

const DataContext = createContext();

const initialData = {
  globalLoading: JSON.parse(localStorage.getItem("globalLoading")) ?? false,
  openEqueueModal: JSON.parse(localStorage.getItem("openEqueueModal")) ?? true,
  todaysEqueueCreated:
    JSON.parse(localStorage.getItem("todaysEqueueCreated")) ?? false,
  openAppointmentModal: localStorage.getItem("openAppointmentModal")
    ? JSON.parse(localStorage.getItem("openAppointmentModal")) ?? false
    : false,
  currentAddress: JSON.parse(localStorage.getItem("currentAddress")),
  selectedBranch: JSON.parse(localStorage.getItem("selectedBranch")),
  selectedDoctor: JSON.parse(localStorage.getItem("selectedDoctor")),
  prescriptionLayout:
    JSON.parse(localStorage.getItem("prescriptionLayout")) ?? true,
  selectedDoctorDetails: JSON.parse(
    localStorage.getItem("selectedDoctorDetails")
  ),
  selectedBranchDetails: JSON.parse(
    localStorage.getItem("selectedBranchDetails")
  ),
  settings: JSON.parse(localStorage.getItem("settings")),
};

const dataReducer = (state, action) => {
  switch (action.type) {
    case "SET_STATE":
      let { payload } = action;
      localStorage.setItem(payload.key, JSON.stringify(payload.value));
      return { ...state, [payload.key]: payload.value };
    default:
      return {};
  }
};

const DataProvider = ({ children }) => {
  const [dataState, dispatch] = useReducer(dataReducer, initialData);
  return (
    <DataContext.Provider value={{ dispatch, dataState }}>
      {children}
    </DataContext.Provider>
  );
};

const useData = () => useContext(DataContext);

export { useData, DataProvider };
