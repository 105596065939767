/** @format */

// eslint-disable-next-line
import React, { lazy, Suspense, useEffect, useState } from "react";
import "./assest/css/general.css";
import "./assest/css/style.scss";
import "./assest/css/antd/buttons.scss";
import "./assest/css/antd/inputs.scss";
import "./assest/css/antd/tables.scss";
import { Route, Routes, useLocation } from "react-router-dom";
import PublicRoutes from "./utils/PublicRoutes";
import { Modal, Spin } from "antd";
import DashboardLayout from "./component/Layout/DashboardLayout";
import axios from "axios";
import { BASE_URL } from "./utils/apiConfig";
import PrivateRoutes from "./utils/PrivateRoutes";
import LoginPage from "./page/LoginPage";
import { useAuth } from "../context/auth-context";
import { useData } from "../context/data-context";
import ChangePasswordModal from "./component/Dashboard/ResetPassword";
import GlobalLoading from "./utils/GlobalLoading";
import NotFound from "./page/404";

axios.defaults.baseURL = BASE_URL;
const token = document.cookie.replace(
  /(?:(?:^|.*;\s*)token\s*=\s*([^;]*).*$)|^.*$/,
  "$1"
);
if (token)
  axios.defaults.headers.common["Authorization"] = `JWT ${token}` ?? "";
const routes = [
  {
    path: "/",
    exact: true,
    Component: lazy(() => import("./page/Dashboard/newDashboard2")),
    roles: [1, 4, 7, 8],
  },
  {
    path: "/select-office",
    exact: true,
    Component: lazy(() => import("./page/SelectOffice")),
    roles: [1, 4, 6, 7, 8],
  },
  {
    path: "/my-account",
    exact: true,
    Component: lazy(() => import("./page/MyAccount")),
    roles: [1, 4, 6, 7, 8],
  },
  {
    path: "/dashboard",
    exact: true,
    Component: lazy(() => import("./page/Dashboard/newDashboard2")),
    roles: [1, 4, 7, 8],
  },
  {
    path: "/dashboard-2",
    exact: true,
    Component: lazy(() => import("./page/Dashboard/newDashboard2")),
    roles: [1, 4, 7, 8],
  },
  {
    path: "/manage-office",
    exact: true,
    Component: lazy(() => import("./page/ManageOffice")),
    roles: [1, 4, 7, 8],
  },
  {
    path: "/equeue",
    exact: true,
    Component: lazy(() => import("./page/Equeue")),
    roles: [1, 4, 6, 7, 8],
  },
  {
    path: "/pause-equeue",
    exact: true,
    Component: lazy(() => import("./page/PauseEqueue")),
    roles: [1, 4, 6, 7, 8],
  },
  {
    path: "/patient-profile",
    exact: true,
    Component: lazy(() => import("./page/PatientProfile/newPatientProfile")),
    roles: [1, 4, 7, 8],
  },
  {
    path: "/prescription",
    exact: true,
    Component: lazy(() => import("./page/PrescriptionModel")),
    roles: [1, 4, 7, 8],
  },
  {
    path: "/opd-register",
    exact: true,
    Component: lazy(() => import("./page/OPDRegister")),
    roles: [1, 4, 7, 8],
  },
  {
    path: "/billing",
    exact: true,
    Component: lazy(() => import("./page/Billing")),
    roles: [1, 4, 7, 8],
  },
  {
    path: "/payment-status",
    exact: true,
    Component: lazy(() => import("./page/PaymentStatus")),
    roles: [1, 4, 7, 8],
  },
  {
    path: "/drug-list",
    exact: true,
    Component: lazy(() => import("./page/DrugList")),
    roles: [1, 7, 8],
  },
  {
    path: "/requested-drug",
    exact: true,
    Component: lazy(() => import("./page/RequestedDrug")),
    roles: [1, 7, 8],
  },
  {
    path: "/admin-dashboard",
    exact: true,
    Component: lazy(() => import("./page/AdminDashboard")),
    roles: [1, 7, 8],
  },
  //CONFIG ROUTES
  {
    path: "/config/office-profile",
    exact: true,
    Component: lazy(() => import("./page/Config/OfficeProfile")),
    roles: [1, 7, 8],
  },
  {
    path: "/config/office-details",
    exact: true,
    Component: lazy(() => import("./page/Config/OfficeDetails")),
    roles: [1, 7, 8],
  },
  {
    path: "/config/doctor-profile",
    exact: true,
    Component: lazy(() => import("./page/Profile")),
    roles: [1, 4, 7, 8],
  },
  {
    path: "/config/prescription",
    exact: true,
    Component: lazy(() => import("./page/Config/PrescriptionNew")),
    roles: [1, 4, 7, 8],
  },
  {
    path: "/pay-out",
    exact: true,
    Component: lazy(() => import("./page/PayOut")),
    roles: [1, 4, 7, 8],
  },
  {
    path: "/tv",
    exact: true,
    Component: lazy(() => import("./page/EqueueOnTv")),
    roles: [1, 4, 6, 7, 8],
  },
  {
    path: "/config/doctor-profile/accomplishment",
    exact: true,
    Component: lazy(() => import("./page/Profile/Accomplishment")),
    roles: [1, 4, 7, 8],
  },
  {
    path: "/config/doctor-profile/achievement",
    exact: true,
    Component: lazy(() => import("./page/Profile/Achievements")),
    roles: [1, 4, 7, 8],
  },
  {
    path: "/config/doctor-profile/biography",
    exact: true,
    Component: lazy(() => import("./page/Profile/Biography")),
    roles: [1, 4, 7, 8],
  },
  {
    path: "/config/doctor-profile/display-profile",
    exact: true,
    Component: lazy(() => import("./page/Profile/DisplayProfile")),
    roles: [1, 4, 7, 8],
  },
  {
    path: "/config/doctor-profile/experience",
    exact: true,
    Component: lazy(() => import("./page/Profile/Experience")),
    roles: [1, 4, 7, 8],
  },
  {
    path: "/config/doctor-profile/membership",
    exact: true,
    Component: lazy(() => import("./page/Profile/Membership")),
    roles: [1, 4, 7, 8],
  },
  {
    path: "/config/doctor-profile/profile-documents",
    exact: true,
    Component: lazy(() => import("./page/Profile/ProfileDocuments")),
    roles: [1, 4, 7, 8],
  },
  {
    path: "/config/doctor-profile/qualification",
    exact: true,
    Component: lazy(() => import("./page/Profile/Qualification")),
    roles: [1, 4, 7, 8],
  },
  {
    path: "/config/doctor-profile/rmp-authorization",
    exact: true,
    Component: lazy(() => import("./page/Profile/RMPAuthorization")),
    roles: [1, 4, 7, 8],
  },
  {
    path: "/config/doctor-profile/profession-fees",
    exact: true,
    Component: lazy(() => import("./page/Profile/ProfessionFees")),
    roles: [1, 4, 7, 8],
  },
  {
    path: "/profile",
    exact: true,
    Component: lazy(() => import("./page/Profile")),
    roles: [1, 4, 7, 8],
  },
  {
    path: "/config/office-details/clinical-registration",
    exact: true,
    Component: lazy(() =>
      import("./page/Config/OfficeDetails/ClinicalRegistration")
    ),
    roles: [1, 7, 8],
  },
  {
    path: "/office-details",
    exact: true,
    Component: lazy(() => import("./page/Config/OfficeDetails/OfficeDetails")),
    roles: [1, 7, 8],
  },
  {
    path: "/config/basic-details",
    exact: true,
    Component: lazy(() => import("./page/Config/BasicDetails")),
    roles: [1, 7, 8],
  },
  {
    path: "/config/office-details/system-type",
    exact: true,
    Component: lazy(() => import("./page/Config/OfficeDetails/SystemType")),
    roles: [1, 7, 8],
  },
  {
    path: "/config/office-details/owner",
    exact: true,
    Component: lazy(() => import("./page/Config/OfficeDetails/Owner")),
    roles: [1, 7, 8],
  },
  {
    path: "/config/office-details/person-incharge",
    exact: true,
    Component: lazy(() => import("./page/Config/OfficeDetails/PersonIncharge")),
    roles: [1, 7, 8],
  },
  {
    path: "/config/office-details/service-areas",
    exact: true,
    Component: lazy(() => import("./page/Config/OfficeDetails/ServiceAreas")),
    roles: [1, 7, 8],
  },
  {
    path: "/config/office-profile/branding",
    exact: true,
    Component: lazy(() => import("./page/Config/OfficeProfile/Branding")),
    roles: [1, 7, 8],
  },
  {
    path: "/config/office-profile/clinical-display",
    exact: true,
    Component: lazy(() =>
      import("./page/Config/OfficeProfile/ClinicalDisplay")
    ),
    roles: [1, 7, 8],
  },
  {
    path: "/config/office-profile/social-media",
    exact: true,
    Component: lazy(() => import("./page/Config/OfficeProfile/SocialMedia")),
    roles: [1, 7, 8],
  },
  {
    path: "/location",
    exact: true,
    Component: lazy(() => import("./page/Config/BasicDetails/Location")),
    roles: [1, 7, 8],
  },
  {
    path: "/timings",
    exact: true,
    Component: lazy(() => import("./page/Config/BasicDetails/Timings")),
    roles: [1, 7, 8],
  },
  {
    path: "/pricing",
    exact: true,
    Component: lazy(() => import("./page/Config/BasicDetails/Pricing")),
    roles: [1, 7, 8],
  },
  {
    path: "/pan-gst-details",
    exact: true,
    Component: lazy(() => import("./page/Config/BasicDetails/PanGSTDetails")),
    roles: [1, 7, 8],
  },
  {
    path: "/bank-details",
    exact: true,
    Component: lazy(() => import("./page/Config/BasicDetails/BankDetails")),
    roles: [1, 7, 8],
  },
  {
    path: "/subscription-payment-status",
    exact: true,
    Component: lazy(() => import("./page/SubscriptionPaymentConfirmation")),
    roles: [1, 4, 7, 8],
  },
];

function App() {
  const location = useLocation();

  const authData = useAuth();
  const dataValue = useData();

  const currUserData = authData?.currUser;

  const [displayLocation, setDisplayLocation] = useState(location);
  const [transitionStage, setTransistionStage] = useState("fadeIn");
  const { globalLoading } = dataValue?.dataState;
  useEffect(() => {
    if (dataValue?.dataState?.selectedDoctor) {
      fetchMasterSetting({ doctor: dataValue?.dataState?.selectedDoctor });
    }
  }, [dataValue?.dataState?.selectedDoctor]);

  const fetchMasterSetting = (params = {}) => {
    axios
      .get("/apiV1/master-settings/", { params: { ...params } })
      .then((res) => {
        if (res?.data?.length > 0) {
          const data = res?.data[0];
          const settingsObj = {
            id: data?.id,
            fontSize: data?.font_size,
            customLayout: data?.custom_layout,
            pageSize: data?.page_size_type,
            topMargin: data?.top_margin,
            bottomMargin: data?.bottom_margin,
            format: data?.prescription_formate,
            language: data?.communication_language,
            showBackground: data?.show_background,
            headerImage: data?.header_image,
            footerImage: data?.footer_image,
            backgroundGraphics: data?.background_graphics,
            signature: data?.signature,
          };
          dataValue.dispatch({
            type: "SET_STATE",
            payload: {
              key: "settings",
              value: settingsObj,
            },
          });
        }
      })
      .catch((err) => console.log(err, "err of master setting"));
  };

  useEffect(() => {
    if (location !== displayLocation) setTransistionStage("fadeOut");
  }, [location, displayLocation]);
  let dashboardLinks = routes
    ?.filter(
      (each) =>
        !each?.path.includes(["/tv"]) && !each.path.includes("/select-office")
    )
    ?.map((each) => each?.path);
  return (
    <React.Fragment>
      <div>
        <Routes>
          <Route element={<PublicRoutes />}>
            <Route exact path="login" element={<LoginPage />} />
            {/* <Route exact path="register" element={<RegisterPage />} /> */}
          </Route>
          <Route element={<PrivateRoutes />}>
            <Route
              element={
                <DashboardLayout
                  visible={dashboardLinks?.includes(window.location.pathname)}
                ></DashboardLayout>
              }
            >
              {routes?.map(({ path, Component, exact, roles }, index) => {
                return (
                  <Route
                    path={path}
                    key={path}
                    exact={exact}
                    element={
                      <Suspense fallback={<GlobalLoading loading={true} />}>
                        {roles?.includes(Number(currUserData?.roles?.id)) ? (
                          <Component />
                        ) : (
                          <NotFound />
                        )}
                      </Suspense>
                    }
                  />
                );
              })}
            </Route>
          </Route>
          <Route path="*" element={<NotFound />} />
        </Routes>
      </div>

      {/* ...........Cange Password Modal................ */}
      {/* <ChangePasswordModal
        openchangePasswordModal={authData?.changePassword}
        setOpenChangePasswordModal={authData?.changePasswordHandler}
      /> */}
      <GlobalLoading loading={globalLoading} />
    </React.Fragment>
  );
}

export default App;
