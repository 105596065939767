import React, { useEffect, useRef, useState } from "react";
import "./index.css";
function OtpInput({ length, className, onOtpChange }) {
  const [otp, setOtp] = useState(new Array(length).fill(""));
  const inputRefs = useRef([]);
  useEffect(() => {
    if (inputRefs.current[0]) {
      inputRefs.current[0].focus();
    }
  }, []);

  const handleInputChange = (value, index) => {
    console.log({ value, index });
    if (isNaN(value)) {
      return;
    }
    let newValue = value.substring(value?.length - 1);
    let newOtp = [...otp];
    newOtp[index] = newValue;
    setOtp([...newOtp]);

    // Notify parent component about OTP change
    onOtpChange(newOtp.join(""));
    if (newValue && inputRefs.current[index + 1]) {
      inputRefs.current[index + 1].focus();
    }
  };

  const handleBackSpace = (e, index) => {
    if (e.key == "Backspace" && !otp[index]) {
      if (inputRefs.current[index - 1]) {
        inputRefs.current[index - 1].focus();
      }
    }
  };

  return (
    <div className={"d-flex justify-content-between " + className}>
      {otp?.map((item, index) => (
        <input
          onChange={(e) => {
            handleInputChange(e.target.value, index);
          }}
          onKeyDown={(e) => {
            handleBackSpace(e, index);
          }}
          ref={(thisInput) => (inputRefs.current[index] = thisInput)}
          key={index}
          value={item}
          className="otp-input"
          type="number"
        />
      ))}
    </div>
  );
}

export default OtpInput;
